* {
  margin: 0px;
}

.header-baby-list {
  height: 10vh;
  width: 100%;
  background-color: pink;
  color: white;
  text-align: center;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  font-family: "Luckiest Guy", cursive;
}

h1 {
  font-size: 45px;
}

.footer {
  height: 10vh;
  width: 100%;
  background-color: pink;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  font-family: "Luckiest Guy", cursive;
}

.App {
  text-align: center;
}

.inputGifts {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  background-color: pink;
  padding: 55px 30px;
  border-radius: 5%;
}

input {
  height: 30px;
  width: 210px;
  border: 2px solid rgb(255, 178, 220);
  border-radius: 8px;
  box-sizing: border-box;
}

.input-title {
  font-family: "Luckiest Guy", cursive;
  font-size: 20px;
  padding: 30px;
}

.post-it {
  height: 80vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
  font-family: arial, sans-serif;
  font-size: 100%;
  margin: 3em;
  background: rgb(193, 193, 221);
  background-image: url("https://www.babycentre.co.uk/ims/2013/10/159450328_wide.jpg") ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
  margin: 0;
}

.giftMap {
  font-family: "Reenie Beanie", arial, sans-serif;
  font-size: 180%;
}

h2,
p {
  font-size: 100%;
  font-weight: normal;
}
ul,
li {
  list-style: none;
  padding: 2px;
}
ul {
  overflow: hidden;
  padding: 3em;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75);
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* margin: 2px; */
  background: rgb(252, 252, 252);
}

ul li a {
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  height: 13.5em;
  width: 13.5em;
  padding: 1em;
  -moz-box-shadow: 5.5px 5.5px 7.5px rgba(33, 33, 33, 1);
  -webkit-box-shadow: 5.5px 5.5px 7.5px rgba(33, 33, 33, 0.7);
  box-shadow: 5.5px 5.5px 7.5px rgba(33, 33, 33, 0.7);
  -moz-transition: -moz-transform 0.15s linear;
  -o-transition: -o-transform 0.15s linear;
  -webkit-transition: -webkit-transform 0.15s linear;
}
ul li {
  margin: 1em;
  float: left;
}
ul li h2 {
  font-size: 140%;
  font-weight: bold;
  padding-bottom: 10px;
}
ul li p {
  font-family: "Reenie Beanie", arial, sans-serif;
  font-size: 110%;
  font-weight: 500;
  color: rgb(224, 15, 137);
}
ul li a {
  -webkit-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
}
ul li:nth-child(even) a {
  -o-transform: rotate(4deg);
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  position: relative;
  top: 5px;
  background: #cfc;
}
ul li:nth-child(3n) a {
  -o-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  position: relative;
  top: -5px;
  background: #ccf;
}
ul li:nth-child(5n) a {
  -o-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  position: relative;
  top: -10px;
}
ul li a:hover,
ul li a:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  position: relative;
  z-index: 5;
}
ol {
  text-align: center;
}
ol li {
  display: inline;
  padding-right: 1em;
}
ol li a {
  color: #fff;
}

.bubbly-button {
  font-family: "Helvetica", "Arial", sans-serif;
  display: inline-block;
  font-size: 0.8em;
  padding: 0.3em 0.3em;
  margin-top: 5px;
  margin-bottom: 30px;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  color: pink;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  position: relative;

  box-shadow: 0 2px 2px rgba(255, 0, 130, 0.5);
}

input[type="text-l"] {
  font-size: 10px;
}

.friends-name {
  color: palevioletred;
  font-weight: 100;
  font-family: "Luckiest Guy", cursive;
}
